<template>
  <v-textarea
    v-model="inputValue"
    :label="!labelTop ? label : null"
    :clearable="isClearable"
    :clear-icon="icons.mdiClose"
    :placeholder="placeholder"
    :maxlength="computedMaxlength"
    :readonly="readonly"
    :disabled="disabled"
    :height="height"
    :rows="computedRows"
    :rules="computedRules"
    :counter="computedCounter"
    :word-count="computedWordCount"
    :prepend-icon="computedPrependIcon"
    :append-icon="appendIcon"
    :hide-details="hideDetails"
    :single-line="labelTop"
    :class="`${noteRequiredField && !disabled ? 'required-field' : ''} ${ai ? 'flip-icon' : ''} ${labelTop ? 'text-area-label-top' : ''} ${prependButtonDisabledClass}`"
    auto-grow
    :loading="loading"
    outlined
    no-resize
    @blur="$emit('blur', $event)"
    @click="$emit('click', $event)"
    @input="$emit('input', $event)"
    @keyup.native="$emit('keyup', $event);"
    @click:prepend="handlePrependClick"
    @click:append="$emit('click:append', true)"
    @click:clear="clearClicked"
    @focus="$emit('focus', $event); if (noClick) $event.target.blur()"
  >
    <template
      v-if="labelTop"
      #prepend
    >
      <strong
        v-if="noteRequiredField && !disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
    <template
      v-if="noteRequiredField && !labelTop"
      #label
    >
      <strong
        v-if="!disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
    <template
      #prepend-inner
    >
      <input-history
        v-if="!inputHistoryCalled && previousHistoryPrepended"
        :title="label"
        :value="previousValue"
        :dos="previousValueDos"
        prepended
      >
      </input-history>
    </template>
    <template
      #append
    >
      <input-history
        v-if="!inputHistoryCalled && !previousHistoryPrepended"
        :title="label"
        :value="previousValue"
        :dos="previousValueDos"
      >
      </input-history>
    </template>
  </v-textarea>
</template>

<script>
/* eslint-disable no-eval */
import {
  mdiClose,
  mdiContentCopy,
  mdiHeadSnowflake, mdiWizardHat,
} from '@mdi/js';
import InputHistory from '@/components/elements/InputHistory.vue'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    rows: {
      type: [String, Number],
      default: 3,
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [String, Number],
      default: 255,
    },
    wordCount: {
      type: [String, Number],
      default: 0,
    },
    clearWarning: {
      type: Boolean,
      default: false,
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: [String, Boolean],
      default: null,
    },
    appendIcon: {
      type: [String, Boolean],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    wizard: {
      type: Boolean,
      default: false,
    },
    ai: {
      type: Boolean,
      default: false,
    },
    paste: {
      type: Boolean,
      default: false,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    noLength: {
      type: Boolean,
      default: false,
    },
    previousValue: {
      type: [String, Boolean, Number],
      default: null,
    },
    previousValueDos: {
      type: String,
      default: null,
    },
    previousHistoryPrepended: {
      type: Boolean,
      default: false,
    },
    inputHistoryCalled: {
      type: Boolean,
      default: false,
    },
    prependButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      icons: {
        mdiHeadSnowflake, mdiWizardHat, mdiContentCopy, mdiClose,
      },
    }
  },
  computed: {
    isClearable() {
      return (!this.readonly && !this.notClearable)
    },
    computedPrependIcon() {
      if (this.prependIcon) return this.prependIcon
      if (this.wizard) return this.icons.mdiWizardHat
      if (this.ai) return this.icons.mdiHeadSnowflake
      if (this.paste) return this.icons.mdiContentCopy

      return this.prependIcon
    },
    noteRequiredField() {
      return this.required && !this.inputValue
    },
    computedRows() {
      const moreRows = (Number(this.counter) === 0 || this.ai || this.wizard) ? 4 : this.rows

      return this.rows !== 3 ? this.rows : moreRows
    },
    computedRules() {
      let rulesArray = []
      if (this.disabled || this.readonly) return rulesArray
      if (this.rules) {
        rulesArray = rulesArray.concat(eval(`this.$validationRules.${this.rules}`))
      }
      if (this.required) {
        rulesArray = rulesArray.concat(this.$validationRules.required)
      }
      if (this.computedCounter && this.counter !== 'false' && this.counter !== '0') {
        rulesArray = rulesArray.concat(this.$validationRules.maxLength(this.counter))
      }
      if (this.computedWordCount && this.wordCount !== 'false' && this.wordCount !== '0' && this.counter !== 'false' && this.counter !== '0') {
        rulesArray = rulesArray.concat(this.$validationRules.minWordCount(this.wordCount))
      }

      return rulesArray
    },
    computedCounter() {
      if (this.noLength) return false
      if (this.counter) return this.counter
      if (this.disabled || this.readonly || this.ai || this.wizard || this.paste) return false
      if (this.counter && this.counter !== 'false') return this.counter

      return false
    },
    computedMaxlength() {
      if (this.maxlength) return this.maxlength
      if (this.ai || this.wizard || this.paste || this.noLength) return false
      if (this.counter && this.counter !== 'false' && this.counter !== '0') return this.counter

      return false
    },
    computedWordCount() {
      if (this.wordCount) return this.wordCount || 0
      if (this.ai || this.wizard || this.paste) return false

      return false
    },
    prependButtonDisabledClass() {
      return this.ai && this.prependButtonDisabled ? 'prepend-icon-disabled' : ''
    },
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
  },
  methods: {
    clearClicked() {
      if (this.clearWarning || this.ai || this.wizard || this.paste) {
        const previous = this.inputValue
        this.$root.confirm({
          title: `Discard ${this.label.replace('...', '')}?`,
          body: `Are you sure you wish to discard ${this.label.toLowerCase().replace('...', '')}?`,
          cancel: 'No',
          confirm: 'Discard',
          confirmColor: 'error',
        }).then(result => {
          if (!result) {
            this.inputValue = previous
          }
        })
      }
    },
    handlePrependClick() {
      if (this.prependButtonDisabled) return

      this.$emit('click:prepend', true)
    },
  },
}
</script>

<style lang="scss">
.text-area-label-top {
  display: block;
  .v-input__prepend-outer {
    display: block;
    margin-top: 4px;
    margin-right: 0;
    padding-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--v-secondary-darken2)
  }
}
.v-counter {
  margin-top: 15px;
}
.prepend-icon-disabled .v-input__prepend-outer .v-icon,
.prepend-icon-disabled .v-input__prepend-outer .v-icon svg {
  color: rgba(96, 96, 96, 0.38) !important;
  fill: rgba(96, 96, 96, 0.38) !important;
  cursor: not-allowed !important;
}
</style>
